import "animate.css";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Card,
    Col,
    Container, Offcanvas, ProgressBar,
    Row,
    Toast
} from "react-bootstrap";
// import OfferPopup from "../Masala-of-india/OfferPopup/OfferPopup";

import Form from "react-bootstrap/Form";
import { FaPepperHot, FaSearch } from "react-icons/fa";
import { GiPlainCircle } from "react-icons/gi";
import { LuVegan } from "react-icons/lu";
import { RiEBike2Line } from "react-icons/ri";
import { TbInfoSquare, TbPaperBag } from "react-icons/tb";
import "react-multi-carousel/lib/styles.css";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation } from "react-router-dom";
import QuickSelection from "../components/QuickSelection/QuickSelection";
import PostApiCall from "../helper/PostAPI";
import Footer from "../pages/Masala-of-india/Footer/Footer";
import { store } from "../pages/context/store";
import "./BarMenu.css";
import BannerBarmenu from "../pages/Masala-of-india/assets/images/banner/banner-bg-image.png";

import { Skeleton } from "antd";
import LeftIcon from "../assets/img/booking-left.png";

import { Helmet } from "react-helmet";
import FoodDetails from "../pages/RecipeDetail/RecipeDetail"
import CommonHomeNavbar from "../pages/Masala-of-india/Navbar/CommonHomeNavbar";

const BarMenu = () => {
    let count = 0;
    const scrollRef = useRef(null);
    const [scroll, setScroll] = useState(false);
    const [selectedItemName, setSelectedItemName] = useState("");
    const {
        showDetails,
        setShowDetails,
        setCurrentItemConfig,
        allcuisines,
        setallcuisines,
        menuListItems,
        setmenuListItems,
        scrollTodiv,
        setScrollTodiv,
        setActiveTab,
    } = useContext(store);
    const { currencyCode, keyStrings } = useContext(store);
    const { cuisines, setcuisines } = useContext(store);
    const { itemId, setItemId } = useContext(store);
    const { itemPrice, setItemPrice } = useContext(store);
    const [categoryId, setCategoryId] = useState(null);
    const [selectSpiceModal, setselectSpiceModal] = useState(false);
    const { itemSpiceLevel, setitemSpiceLevel } = useContext(store);
    const { setSelectedProperties } = useContext(store);
    const { addOnGroup, setAddOnGroup } = useContext(store);
    const { setAddOnList, addOnList } = useContext(store);
    //add property
    const { property, setProperty } = useContext(store);
    const {
        productDetailBanner,
        setProductDetailBanner,
        setProductComplementry,
        productDetailItem,
        setProductDetailIem,
        productDetailCuisines,
        setProductDetailCuisines,
        productDetailDescription,
        setProductDetailDescription,
        productDetailLocation,
        setSelectedMappedProperty,
    } = useContext(store);
    const { setPropertyList, propertyList } = useContext(store);
    const { selectedCartLineId, setSelectedCartLineId } = useContext(store);
    const { searchText, setSearchText } = useContext(store);
    const [showRating, setShowRating] = useState([]);
    const toggleShowRating = (index) => {
        const newShow = [...showRating];
        newShow[index] = !newShow[index];
        setShowRating(newShow);
    };

    const { showLoginModal, setShowLoginModal } = useContext(store);
    const handleShowLoginModal = () => setShowLoginModal(true);
    const handleCloseDetails = () => setShowDetails(false);

    useEffect(() => {
        if (window.pageYOffset > 300) {
            setScroll("scroll");
        } else {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 300);
            });
        }
    }, []);
    const windowScrollY = (id) => {
        setScrollTodiv(id);
        // window.scroll({
        //   top: window.innerWidth > 967 ? 350 : 500, // Calculate the absolute position of the filtered items
        //   behavior: "smooth",
        // });
    };
    React.useEffect(() => {
        if (scrollTodiv) {
            const targetDiv = document.getElementById(scrollTodiv);

            if (targetDiv) {
                const rect = targetDiv.getBoundingClientRect();
                const targetScrollTop = rect.top + window.pageYOffset - 100;

                const scrollStep = (targetScrollTop - window.pageYOffset) / 20; // Divide the scroll into 20 steps
                let currentScrollTop = window.pageYOffset;

                const smoothScroll = () => {
                    if (
                        (scrollStep > 0 && currentScrollTop < targetScrollTop) ||
                        (scrollStep < 0 && currentScrollTop > targetScrollTop)
                    ) {
                        currentScrollTop += scrollStep;
                        window.scrollTo({
                            top: currentScrollTop,
                            behavior: "auto", // Disable smooth scrolling for this operation
                        });
                        requestAnimationFrame(smoothScroll);
                    } else {
                        window.scrollTo({
                            top: targetScrollTop,
                            behavior: "smooth", // Re-enable smooth scrolling for final step
                        });
                        setScrollTodiv(null);
                    }
                };

                smoothScroll();
            }
        }
    }, [scrollTodiv]);
    useEffect(() => {
        PostApiCall.postRequest(
            {
                whereClause: "",
                columns: "*",
            },
            "getCategoryDetails"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setmenuListItems(
                        obj1.message.filter((dat) => dat.fld_enabled_flag === "Yes")
                    );
                    //localStorage.setItem("BannerDetails", JSON.stringify(obj1.message))
                    Notiflix.Loading.Remove();
                }
            })
        );
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
        getMenuList();
    }, []);

    const getDetails = (Cuisine) => {
        var addondata = null;
        var propertydata = null;
        setItemPrice(Number(Cuisine.FLD_Pricing_new).toFixed(2));
        setProductDetailBanner(Cuisine.FLD_Image);
        setProductComplementry(Cuisine.fld_complementry);
        setProductDetailIem(Cuisine.FLD_Name);
        setProductDetailCuisines("Serves" + Cuisine.FLD_Service_Info);
        setProductDetailDescription(Cuisine.FLD_Description);
        setSelectedCartLineId(Cuisine.fld_cart_dtl_id);
        setItemId(Cuisine.FLD_Item_ID);
        PostApiCall.postRequest(
            {
                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties    `,
            },
            "getItemAddOnMaster"
        ).then((result) =>
            result?.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    if (obj.data.length > 0) {
                        setAddOnGroup(obj.data);
                        addondata = obj.data;
                    } else {
                        setAddOnGroup(null);
                        addondata = null;
                    }
                    if (addOnGroup != null) {
                        PostApiCall.postRequest(
                            {
                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                columns: ` *  `,
                            },
                            "getItemAddOnMaster"
                        ).then((result) =>
                            result?.json().then((obj) => {
                                if (result.status == 200 || result.status == 201) {
                                    if (obj.data.length > 0) {
                                        setAddOnList(obj.data);
                                    } else {
                                        if (Cuisine.total_item_in_cart > 0) {
                                            setCurrentItemConfig(Cuisine.ConfigDetails);
                                            // setShowCopyConfig(true);
                                        } else {
                                        }
                                        setAddOnList(null);
                                    }
                                }
                            })
                        );
                    } else {
                    }
                }
            })
        );

        // property
        PostApiCall.postRequest(
            {
                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
            },
            "GetItemProperty"
        ).then((result) =>
            result?.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    if (obj.data.length > 0) {
                        setProperty(obj.data);
                        propertydata = obj.data;
                    } else {
                        setProperty(null);
                        propertydata = null;
                    }

                    if (propertydata != null) {
                        PostApiCall.postRequest(
                            {
                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                columns: ` *  `,
                            },
                            "GetItemProperty"
                        ).then((result) =>
                            result?.json().then((obj) => {
                                if (result.status == 200 || result.status == 201) {
                                    if (obj.data.length > 0) {
                                        if (Cuisine.total_item_in_cart > 0) {
                                            setCurrentItemConfig(Cuisine.ConfigDetails);
                                            // setShowCopyConfig(true);
                                        } else {
                                        }
                                        setPropertyList(obj.data);
                                    } else {
                                        setPropertyList(null);
                                    }
                                }
                            })
                        );

                        setCategoryId(Cuisine.FLD_Cat_Id);
                        setselectSpiceModal(true);
                        setitemSpiceLevel(Cuisine.FLD_Spice_Level);
                    } else {
                        if (Cuisine.total_item_in_cart > 0) {
                            setCurrentItemConfig(Cuisine.ConfigDetails);
                            //setShowCopyConfig(true);
                        }
                    }
                }
            })
        );
    };

    const getMenuList = () => {
        var Order = localStorage.getItem("OrderData");
        var Orderdetails = JSON.parse(Order);
        PostApiCall.postRequest(
            {
                categoryid: categoryId,
                orderid:
                    localStorage.getItem("OrderData") == null ? 0 : Orderdetails.order_id,
            },
            "getFoodMenuOrder"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setcuisines(obj1.message);
                    setallcuisines(obj1.message);
                    setselectSpiceModal(false);
                    setShowRating(Array(obj1.message.length).fill(false));
                    //localStorage.setItem("BannerDetails", JSON.stringify(obj1.message))
                    Notiflix.Loading.Remove();
                }
            })
        );
    };
    const handleShowDetails = () => {
        setSelectedProperties([]);
        setSelectedMappedProperty(null);
        setShowDetails(true);
    };
    const [active, setActive] = useState("All");
    const location = useLocation();

    const getHelmetContent = () => {
        if (location.pathname === "/menu") {
            return (
                <Helmet>
                    {/* Meta tags */}
                    <title>
                        Menu - Best Indian Restaurant in Seattle - Order Online{" "}
                    </title>
                    <meta
                        name="description"
                        content='Welcome to "Masala of India Cuisine" the best Indian restaurant in Seattle. We serve a variety of traditional Indian dishes as well as several of our own creations.'
                    />
                    <meta
                        name="keywords"
                        content="Menu, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
                    />

                    {/* OG tags */}
                    <meta
                        property="og:title"
                        content="Menu - Best Indian Restaurant in Seattle - Order Online"
                    />
                    <meta
                        property="og:description"
                        content='Welcome to " Masala of India Cuisine" the best Indian restaurant in Seattle. We serve a variety of traditional Indian dishes as well as several of our own creations.'
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.masalaofindia.com/menu" />
                    <meta
                        property="og:image"
                        content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
                    />
                    <meta property="og:site_name" content="masala of india" />

                    {/* Twitter tags */}
                    <meta
                        name="twitter:title"
                        content="Menu - Best Indian Restaurant in Seattle - Order Online"
                    />
                    <meta
                        name="twitter:description"
                        content='Welcome to " Masala of India Cuisine" the best Indian restaurant in Seattle. We serve a variety of traditional Indian dishes as well as several of our own creations.'
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
                    />
                    <meta name="twitter:site" content="masala of india" />
                    <link rel="canonical" href="https://www.masalaofindia.com/menu"></link>
                </Helmet>
            );
        }
        return null;
    };
    return (
        <div className="order-online-page">
            {getHelmetContent()}
            <CommonHomeNavbar />
            <QuickSelection />
            <section className="banner-section-barmenu-content my-4">
                <h1
                    className="text-center pt-3 pb-1 pb-lg-4 category-title oder-online-category-title menu-page-heading"
                    dangerouslySetInnerHTML={{
                        __html:
                            keyStrings?.filter(
                                (item) => item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                            )[0] != null
                                ? keyStrings?.filter(
                                    (item) =>
                                        item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                                )[0].FLD_Key_Value
                                : "",
                    }}></h1>
                <div className=" banner-section-barmenu-button">
                    <div className="d-flex gap-4">
                        <a
                            href="/order-online"
                            // className="text-white onliner-order-desktop btn-lg rest-share-link d-md-block d-none"
                            className="btn-lg menu-btn active"
                        >
                            Bar Menu
                        </a>
                        <a
                            href="/order-online"
                            // className="btn-lg d-md-block d-none"
                            className="btn-lg menu-btn"
                        >
                            Food Menu
                        </a>
                    </div>
                </div>

            </section>
            <section className="mt-md-4 banner-section-barmenu">

                <Container fluid>
                    <Row>
                        <Col>
                            <img src={BannerBarmenu} className="img-fluid" />

                        </Col>
                    </Row>


                    {/* {keyStrings != null ? (
                        <Row>
                            <h1
                                className="text-center pt-3 pb-4 category-title oder-online-category-title menu-page-heading"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        keyStrings?.filter(
                                            (item) => item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                                        )[0] != null
                                            ? keyStrings?.filter(
                                                (item) =>
                                                    item.FLD_Key_Name == "HOME_PAGE_CATEGORY_TEXT"
                                            )[0].FLD_Key_Value
                                            : "",
                                }}></h1>
                        </Row>
                    ) : (
                        <div></div>
                    )} */}
                    {/* <div
                        className="mx-0 oder-online-category"
                        data-category-columns={
                            menuListItems.length % 2 == 0
                                ? menuListItems.length / 2
                                : (menuListItems.length + 1) / 2
                        }>
                        {menuListItems
                            // .slice(0, visible?.start)
                            .map((MenuListItem) => (
                                <div
                                    className="oder-online-category-card"
                                    key={MenuListItem.fld_category_id}>
                                    <div className="text-center mx-lg-2 mx-0 cursor-pointer">
                                        <div className="item-image category-box">
                                            <img
                                                onClick={(e) => {
                                                    setActiveTab(MenuListItem.fld_category_id);
                                                    windowScrollY(MenuListItem.fld_category_id);
                                                    setSearchText(e.target.getAttribute("alt"));
                                                    setActive("");
                                                }}
                                                className="img-fluid"
                                                src={MenuListItem.fld_image}
                                                alt={MenuListItem.fld_name}
                                            />
                                        </div>
                                        <p className="item-name">{MenuListItem.fld_name}</p>
                                    </div>
                                </div>
                            ))}
                    </div> */}
                </Container>
            </section>
            {/* <section className={scroll ? "sticky-search-bar shadow-sm mt-4" : "mt-2"}>
                <Container fluid>
                    <Card className={scroll ? "" : "shadow-search"}>
                        <Card.Body className="py-0 pe-0">
                            <Row className="align-items-center w-100 me-1">
                                <Col
                                    lg={6}
                                    className="py-3 pe-0 d-flex justify-content-between align-content-center gap-2">
                                    <div className="flex-div position-relative">
                                        <Form.Control
                                            type="text"
                                            value={searchText}
                                            onFocus={windowScrollY}
                                            onChange={(e) => {
                                                const removeFav = allcuisines;
                                                setcuisines(
                                                    removeFav.filter((product) =>
                                                        product.search_field
                                                            .toLowerCase()
                                                            .includes(e.target.value.toLowerCase())
                                                    )
                                                );
                                                setSearchText(e.target.value);
                                            }}
                                            placeholder="Search for a dish"
                                        />
                                        <FaSearch className="search-icon" />
                                    </div>
                                </Col>
                                <Col lg={6} className="pe-0">
                                    <Row className="food-type-filter-row">
                                        <button
                                            className={`item-category-filter all ${active == "All" && "active"
                                                }`}
                                            onClick={() => {
                                                setActive("All");
                                                setcuisines(allcuisines);
                                                setSearchText("");
                                            }}>
                                            All Items
                                        </button>
                                        <button
                                            type="button"
                                            className={`item-category-filter veg ${active == "Vegetarian" && "active"
                                                }`}
                                            onClick={() => {
                                                setActive("Vegetarian");
                                                const removeFav = allcuisines;
                                                setcuisines(
                                                    removeFav.filter(
                                                        (product) => product.FLD_Food_Type === "Vegetarian"
                                                    )
                                                );
                                                setSearchText("");
                                            }}>
                                            <GiPlainCircle />
                                            Veg
                                        </button>
                                        <button
                                            type="button"
                                            className={`item-category-filter nonveg ${active == "NonVegetarian" && "active"
                                                }`}
                                            onClick={() => {
                                                setActive("NonVegetarian");
                                                const removeFav = allcuisines;
                                                setcuisines(
                                                    removeFav.filter(
                                                        (product) =>
                                                            product.FLD_Food_Type === "NonVegetarian"
                                                    )
                                                );
                                                setSearchText("");
                                            }}>
                                            <GiPlainCircle />
                                            Non Veg
                                        </button>
                                        <button
                                            style={{ display: count > 0 ? "block" : "none" }}
                                            className={`item-category-filter vegan ${active == "Vegan" && "active"
                                                }`}
                                            onClick={() => {
                                                setActive("Vegan");
                                                const removeFav = allcuisines;
                                                setcuisines(
                                                    removeFav.filter((product) => {
                                                        if (product.FLD_Food_Type === "Vegan") {
                                                            count++;
                                                            return product;
                                                        } else {
                                                            return false;
                                                        }
                                                    })
                                                );
                                                setSearchText("");
                                            }}>
                                            <LuVegan />
                                            Vegan
                                        </button>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </section> */}
            <section className="pt-3">
                <Container fluid>
                    <Row>
                        {menuListItems?.map((data) => {
                            const filteredCuisines = cuisines.filter(
                                (dat) => dat.FLD_Cat_Id === data.fld_category_id
                            );
                            return (
                                <>
                                    {filteredCuisines.length > 0 && (
                                        <Col
                                            lg={12}
                                            id={data.fld_category_id}
                                            ref={scrollRef}
                                            className="mb-4">
                                            <Card className="shadow-search">
                                                <Card.Body className="pt-0">
                                                    <div className="sticky-top sticky-top-category-heading barmenu-sticky-top-category-heading d-flex align-items-center justify-content-center">
                                                        <img src={LeftIcon} alt="masala-of-india" />
                                                        <h2 className="mx-3 me-2">{data.fld_name}</h2>
                                                        <img src={LeftIcon} alt="masala-of-india" />
                                                    </div>
                                                    <Row>
                                                        {cuisines.length > 0 ? (
                                                            cuisines
                                                                .filter((item) => {
                                                                    if (data.fld_category_id == item.FLD_Cat_Id) {
                                                                        return item;
                                                                    }
                                                                })
                                                                .map((Cuisine, index) => (
                                                                    <Col
                                                                        xl={4}
                                                                        md={6}
                                                                        className="item-card-wrapper"
                                                                        key={Cuisine.FLD_Item_ID}>
                                                                        <div className="item-card">
                                                                            <div className="recipe-img-wrapper recipe-img-wrapper-tab-view d-none">
                                                                                <Link
                                                                                    onClick={(e) => {
                                                                                        var addondata = null;
                                                                                        var propertydata = null;
                                                                                        setItemPrice(
                                                                                            Number(
                                                                                                Cuisine.FLD_Pricing_new
                                                                                            ).toFixed(2)
                                                                                        );
                                                                                        setSelectedItemName(
                                                                                            Cuisine.FLD_Name
                                                                                        );
                                                                                        setProductDetailBanner(
                                                                                            Cuisine.FLD_Image
                                                                                        );
                                                                                        setProductComplementry(
                                                                                            Cuisine.fld_complementry
                                                                                        );
                                                                                        setProductDetailIem(
                                                                                            Cuisine.FLD_Name
                                                                                        );
                                                                                        setProductDetailCuisines(
                                                                                            "Serves" +
                                                                                            Cuisine.FLD_Service_Info
                                                                                        );
                                                                                        setProductDetailDescription(
                                                                                            Cuisine.FLD_Description
                                                                                        );
                                                                                        setSelectedCartLineId(
                                                                                            Cuisine.fld_cart_dtl_id
                                                                                        );
                                                                                        setselectSpiceModal(true);
                                                                                        setitemSpiceLevel(
                                                                                            Cuisine.FLD_Spice_Level
                                                                                        );
                                                                                        setItemId(Cuisine.FLD_Item_ID);
                                                                                        PostApiCall.postRequest(
                                                                                            {
                                                                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                                                columns: ` distinct FLD_Addon_Group,FLD_Max_Addon,FLD_Max_Properties    `,
                                                                                            },
                                                                                            "getItemAddOnMaster"
                                                                                        ).then((result) =>
                                                                                            result?.json().then((obj) => {
                                                                                                // //console.log(obj.data);
                                                                                                if (
                                                                                                    result.status == 200 ||
                                                                                                    result.status == 201
                                                                                                ) {
                                                                                                    handleShowDetails();
                                                                                                    if (obj.data.length > 0) {
                                                                                                        setAddOnGroup(obj.data);
                                                                                                        addondata = obj.data;
                                                                                                    } else {
                                                                                                        setAddOnGroup(null);
                                                                                                        addondata = null;
                                                                                                    }
                                                                                                    if (addOnGroup != null) {
                                                                                                        PostApiCall.postRequest(
                                                                                                            {
                                                                                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                                                                columns: ` *  `,
                                                                                                            },
                                                                                                            "getItemAddOnMaster"
                                                                                                        ).then((result) =>
                                                                                                            result
                                                                                                                ?.json()
                                                                                                                .then((obj) => {
                                                                                                                    // //console.log(obj.data);
                                                                                                                    if (
                                                                                                                        result.status ==
                                                                                                                        200 ||
                                                                                                                        result.status == 201
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            obj.data.length >
                                                                                                                            0
                                                                                                                        ) {
                                                                                                                            setAddOnList(
                                                                                                                                obj.data
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                })
                                                                                                        );
                                                                                                    } else {
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        );

                                                                                        // property
                                                                                        PostApiCall.postRequest(
                                                                                            {
                                                                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                                                columns: ` distinct FLD_Property_Name,FLD_Max_Addon,FLD_Max_Properties    `,
                                                                                            },
                                                                                            "GetItemProperty"
                                                                                        ).then((result) =>
                                                                                            result?.json().then((obj) => {
                                                                                                // //console.log(obj.data);
                                                                                                if (
                                                                                                    result.status == 200 ||
                                                                                                    result.status == 201
                                                                                                ) {
                                                                                                    handleShowDetails();
                                                                                                    if (obj.data.length > 0) {
                                                                                                        setProperty(obj.data);
                                                                                                        propertydata = obj.data;
                                                                                                    } else {
                                                                                                        setProperty(null);
                                                                                                        propertydata = null;
                                                                                                    }

                                                                                                    if (propertydata != null) {
                                                                                                        PostApiCall.postRequest(
                                                                                                            {
                                                                                                                WhereClause: ` where FLD_Item_Id = ${Cuisine.FLD_Item_ID}`,
                                                                                                                columns: ` *  `,
                                                                                                            },
                                                                                                            "GetItemProperty"
                                                                                                        ).then((result) =>
                                                                                                            result
                                                                                                                ?.json()
                                                                                                                .then((obj) => {
                                                                                                                    // //console.log(obj.data);
                                                                                                                    if (
                                                                                                                        result.status ==
                                                                                                                        200 ||
                                                                                                                        result.status == 201
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            obj.data.length >
                                                                                                                            0
                                                                                                                        ) {
                                                                                                                            if (
                                                                                                                                Cuisine.total_item_in_cart >
                                                                                                                                0
                                                                                                                            ) {
                                                                                                                                setCurrentItemConfig(
                                                                                                                                    Cuisine.ConfigDetails
                                                                                                                                );
                                                                                                                            }

                                                                                                                            setPropertyList(
                                                                                                                                obj.data
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                })
                                                                                                        );

                                                                                                        setCategoryId(
                                                                                                            Cuisine.FLD_Cat_Id
                                                                                                        );
                                                                                                    } else {
                                                                                                        if (
                                                                                                            Cuisine.total_item_in_cart >
                                                                                                            0
                                                                                                        ) {
                                                                                                            setCurrentItemConfig(
                                                                                                                Cuisine.ConfigDetails
                                                                                                            );
                                                                                                            // setShowCopyConfig(true);
                                                                                                            handleShowDetails();
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        );
                                                                                    }}>
                                                                                    {/* <img
                                                                                        className="recipe-img order-lg-1 order-2"
                                                                                        src={Cuisine.FLD_Image}
                                                                                        alt={Cuisine.FLD_Name}
                                                                                    /> */}
                                                                                </Link>
                                                                            </div>
                                                                            <div className="w-100 px-lg-3 px-0 py-lg-0 py-2 order-lg-2 order-1">
                                                                                <div>
                                                                                    <div>
                                                                                        <p className="recipe-best">
                                                                                            {Cuisine.food_type && (
                                                                                                <img
                                                                                                    src={Cuisine.food_type}
                                                                                                    className="recipe-type"
                                                                                                    alt="nonveg"
                                                                                                />
                                                                                            )}
                                                                                            <FaPepperHot
                                                                                                className={
                                                                                                    Cuisine.FLD_Spice_Level
                                                                                                }
                                                                                                style={{
                                                                                                    display:
                                                                                                        Cuisine.FLD_Spice_Level ==
                                                                                                            "Yes"
                                                                                                            ? "flex"
                                                                                                            : "none",
                                                                                                }}
                                                                                            />
                                                                                            <span
                                                                                                style={{
                                                                                                    display:
                                                                                                        Cuisine.contains_egg != null
                                                                                                            ? "flex"
                                                                                                            : "none",
                                                                                                }}
                                                                                                className="egg-recipe">
                                                                                                {Cuisine.contains_egg}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    display:
                                                                                                        Cuisine.best_seller != null
                                                                                                            ? "flex"
                                                                                                            : "none",
                                                                                                }}
                                                                                                className="best-seller">
                                                                                                {Cuisine.best_seller}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    display:
                                                                                                        Cuisine.customisable != null
                                                                                                            ? "flex"
                                                                                                            : "none",
                                                                                                }}
                                                                                                className="customisable">
                                                                                                {Cuisine.customisable}
                                                                                            </span>
                                                                                            <img
                                                                                                style={{
                                                                                                    display:
                                                                                                        Cuisine.chefs_choice != null
                                                                                                            ? "flex"
                                                                                                            : "none",
                                                                                                }}
                                                                                                src={Cuisine.chefs_choice}
                                                                                                className="recipe-type"
                                                                                                alt="Chef's Special"
                                                                                            />
                                                                                        </p>
                                                                                        <p className="recipe-name">
                                                                                            <Link
                                                                                                onClick={() => {
                                                                                                    setitemSpiceLevel(
                                                                                                        Cuisine.FLD_Spice_Level
                                                                                                    );
                                                                                                    getDetails(Cuisine);
                                                                                                    handleShowDetails();
                                                                                                }}>
                                                                                                {Cuisine.FLD_Name}
                                                                                            </Link>
                                                                                        </p>
                                                                                        <p className="recipe-category">
                                                                                            <span>
                                                                                                {Cuisine.Category_name}
                                                                                            </span>
                                                                                            {Cuisine.FLD_Service_Info && (
                                                                                                <span>
                                                                                                    &nbsp;|&nbsp; Serves (
                                                                                                    {Cuisine.FLD_Service_Info})
                                                                                                </span>
                                                                                            )}
                                                                                        </p>
                                                                                        {Cuisine.fld_complementry && (
                                                                                            <div className="complementry-box">
                                                                                                <span>
                                                                                                    Complimentary{" "}
                                                                                                </span>
                                                                                                <p>
                                                                                                    {Cuisine.fld_complementry}
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                        <p className="recipe-description">
                                                                                            {Cuisine.FLD_Description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <Row className="justify-content-between align-items-end">
                                                                            <Col xs="12" className="pe-lg-0">
                                                                                <div className="d-flex justify-content-between w-100 align-items-end item-card-bottom barmenu-item-card-bottom pe-2 pe-lg-0">
                                                                                    <div className="py-1 ps-1 px-lg-3 d-flex justify-content-between align-items-center">
                                                                                        <div
                                                                                            className="cursor-pointer"
                                                                                            onClick={() => {
                                                                                                toggleShowRating(index);
                                                                                            }}>
                                                                                            <ReactStars
                                                                                                count={5}
                                                                                                value={Cuisine.FLD_Rating}
                                                                                                size={20}
                                                                                                edit={false}
                                                                                                isHalf={true}
                                                                                                activeColor="#ffd700"
                                                                                            />

                                                                                            <Toast
                                                                                                show={showRating[index]}
                                                                                                onClose={() =>
                                                                                                    toggleShowRating(index)
                                                                                                }
                                                                                                className="rating-popup">
                                                                                                <Toast.Header className="justify-content-between">
                                                                                                    <div className="d-flex flex-column">
                                                                                                        <div className="d-flex align-items-center gap-2">
                                                                                                            <ReactStars
                                                                                                                count={5}
                                                                                                                value={Cuisine.FLD_Rating}
                                                                                                                size={20}
                                                                                                                onChange={(rating) => {
                                                                                                                    var login =
                                                                                                                        localStorage.getItem(
                                                                                                                            "LoginDetailsWeb"
                                                                                                                        );
                                                                                                                    var logindetails = login
                                                                                                                        ? JSON.parse(login)[0]
                                                                                                                        : null;
                                                                                                                    if (login === null) {
                                                                                                                        handleShowLoginModal();
                                                                                                                    } else {
                                                                                                                        PostApiCall.postRequest(
                                                                                                                            {
                                                                                                                                rating: rating,
                                                                                                                                variantId:
                                                                                                                                    Cuisine.FLD_Item_ID,
                                                                                                                                userId:
                                                                                                                                    localStorage.getItem(
                                                                                                                                        "LoginDetailsWeb"
                                                                                                                                    ) == null
                                                                                                                                        ? null
                                                                                                                                        : logindetails.CustomerId,
                                                                                                                            },
                                                                                                                            "UpdateItemRating"
                                                                                                                        ).then((result) =>
                                                                                                                            result
                                                                                                                                ?.json()
                                                                                                                                .then((obj) => {
                                                                                                                                    if (
                                                                                                                                        result.status ==
                                                                                                                                        200 ||
                                                                                                                                        result.status ==
                                                                                                                                        201
                                                                                                                                    ) {
                                                                                                                                        Notiflix.Notify.Success(
                                                                                                                                            "Thank you for rating."
                                                                                                                                        );
                                                                                                                                        getMenuList();
                                                                                                                                    } else {
                                                                                                                                        Notiflix.Notify.failure(
                                                                                                                                            obj.message
                                                                                                                                        );
                                                                                                                                    }

                                                                                                                                })
                                                                                                                        );
                                                                                                                    }
                                                                                                                }}
                                                                                                                edit={true}
                                                                                                                isHalf={false}
                                                                                                                activeColor="#ffd700"
                                                                                                            />
                                                                                                            <h6 className="fw-bold d-block mb-0">
                                                                                                                {Cuisine.FLD_Rating} out
                                                                                                                of 5
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <p className="d-block mb-0 small">
                                                                                                                {Cuisine.rating_count}{" "}
                                                                                                                global ratings
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Toast.Header>
                                                                                                {Cuisine.rating_breakup
                                                                                                    ?.split("#")
                                                                                                    .map((data) => {
                                                                                                        return (
                                                                                                            <Toast.Body className="bg-white">
                                                                                                                <div className="d-flex gap-2 align-items-center">
                                                                                                                    <p className="mb-0 text-end">
                                                                                                                        {data?.split(",")[0]}{" "}
                                                                                                                        Star
                                                                                                                    </p>
                                                                                                                    <div>
                                                                                                                        <ProgressBar
                                                                                                                            variant="warning"
                                                                                                                            now={
                                                                                                                                data.split(",")[1]
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <p className="mb-0">
                                                                                                                        {data.split(",")[1]}%
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </Toast.Body>
                                                                                                        );
                                                                                                    })}
                                                                                            </Toast>
                                                                                        </div>
                                                                                        {/* <div className="d-flex gap-2 align-items-center"> */}
                                                                                        {/* <FaRegHeart className="wishlist-share cursor-pointer" /> */}
                                                                                        {/* <FaRegShareSquare className="wishlist-share cursor-pointer" /> */}
                                                                                        {/* </div> */}
                                                                                    </div>
                                                                                    {/* </Col>

                                                                            <Col xs="6"> */}
                                                                                    <div className="d-flex justify-content-end align-items-end gap-3 py-1 me-lg-4">
                                                                                        {/* <div className="d-flex gap-2">
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    Cuisine.delivery_type ===
                                                                                                        "TAKEAWAY" ||
                                                                                                        Cuisine.delivery_type ===
                                                                                                        "BOTH"
                                                                                                        ? "flex"
                                                                                                        : "none",
                                                                                            }}>
                                                                                            <RiEBike2Line className="wishlist-share" />
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    Cuisine.delivery_type ===
                                                                                                        "DELIVERY" ||
                                                                                                        Cuisine.delivery_type ===
                                                                                                        "BOTH"
                                                                                                        ? "flex"
                                                                                                        : "none",
                                                                                            }}>
                                                                                            <TbPaperBag className="wishlist-share" />
                                                                                        </span>
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                setitemSpiceLevel(
                                                                                                    Cuisine.FLD_Spice_Level
                                                                                                );
                                                                                                getDetails(Cuisine);
                                                                                                handleShowDetails();
                                                                                            }}
                                                                                            style={{
                                                                                                display:
                                                                                                    Cuisine.delivery_type ===
                                                                                                        "DELIVERY" ||
                                                                                                        Cuisine.delivery_type ===
                                                                                                        "BOTH"
                                                                                                        ? "flex"
                                                                                                        : "none",
                                                                                            }}>
                                                                                            <TbInfoSquare
                                                                                                className="wishlist-share cursor-pointer"
                                                                                                title="Read More"
                                                                                            />
                                                                                        </div>
                                                                                    </div> */}
                                                                                        <div className="d-flex gap-3">
                                                                                            <p className="recipe-price">
                                                                                                {Cuisine.FLD_Pricing_new != 0 && (
                                                                                                    <>
                                                                                                        <span className="me-1">
                                                                                                            {currencyCode}{" "}
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            {Number(
                                                                                                                Cuisine.FLD_Pricing_new
                                                                                                            ).toFixed(2)}
                                                                                                        </span>
                                                                                                    </>
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                ))
                                                        ) : (
                                                            <Row>
                                                                <Col
                                                                    xl={4}
                                                                    md={6}
                                                                    className="item-card-wrapper">
                                                                    <div className="item-card">
                                                                        <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                                                            <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                                                        </div>
                                                                        <Skeleton />
                                                                    </div>
                                                                </Col>
                                                                <Col
                                                                    xl={4}
                                                                    md={6}
                                                                    className="item-card-wrapper">
                                                                    <div className="item-card">
                                                                        <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                                                            <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                                                        </div>
                                                                        <Skeleton />
                                                                    </div>
                                                                </Col>
                                                                <Col
                                                                    xl={4}
                                                                    md={6}
                                                                    className="item-card-wrapper">
                                                                    <div className="item-card">
                                                                        <div className="recipe-img-wrapper recipe-img-wrapper-tab-view">
                                                                            <Skeleton.Image className="recipe-img order-lg-1 order-2 me-3 Item-image-Skeleton" />
                                                                        </div>
                                                                        <Skeleton />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )}
                                </>
                            );
                        })}
                    </Row>
                </Container>
            </section>
            <Offcanvas
                show={showDetails}
                onHide={handleCloseDetails}
                placement="bottom"
                className="recipe-details">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <p className="spice-food-name-color fw-bold mb-1">
                            {selectedItemName}
                        </p>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="overflow-lg-hidden">
                    <FoodDetails />
                </Offcanvas.Body>
            </Offcanvas>
            <div className="">
                <Footer />
            </div>
        </div>
    );
};

export default BarMenu;
