import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import CommonHomeNavbar from "../Navbar/CommonHomeNavbar";
import "./StaticPage.css";

function Privacy() {
  return (
    <>
      <CommonHomeNavbar />

      <Helmet>
        {/* meta tags */}
        <title>
          Privacy Policy | Masala of India | Indian Restaurant in Seattle        </title>
        <meta
          name="description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Masala Of India, best indian restaurant in  seattle, indian cuisine in seattle, indian food lunch buffet in seattle, indian restaurants near me delivery, online indian food delivery near me, indian restaurants in seattle washington, best indian restaurants in seattle, indian buffet bellevue,indian food buffets, best indian restaurants in bellevu, indian restaurants in seattle"
        />

        {/* OG tags */}
        <meta
          property="og:title"
          content="Privacy Policy | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          property="og:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.masalaofindia.com/"
        />
        <meta
          property="og:image"
          content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta property="og:site_name" content="masala of india" />

        {/* Twitter tags */}
        <meta
          name="twitter:title"
          content="Privacy Policy | Masala of India | Indian Restaurant in Seattle"
        />
        <meta
          name="twitter:description"
          content="Discover how our privacy policy ensures the protection of your personal information. Learn about our commitment to safeguarding your privacy today."
        />
        <meta
          name="twitter:image"
          content="https://www.masalaofindia.com/static/media/masala-of-india.c5a7d2004e43f4db1721.png"
        />
        <meta name="twitter:site" content="masala of india" />
        <link
          rel="canonical"
          href="https://www.masalaofindia.com/privacy"></link>
      </Helmet>

      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header-box">
                <h1 class="text-anime">Privacy Policy</h1>
                <nav>
                  <ol class="breadcrumb wow fadeInUp" data-wow-delay="0.50s">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active">Privacy Policy</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid static-page">
        <div className="row">
          <div className="col-12">
            <h3 className="text-center">Privacy Policy</h3>
            <p>
              At Masala of India, we are committed to protecting your personal
              information and ensuring your privacy when using our online food
              ordering platform. This Privacy Policy outlines how we collect,
              use, and safeguard your data in accordance with U.S. privacy laws.
            </p>
            <ul>
              <b>1. Information Collection</b>
              <li>
                When you place an order or create an account, we may collect
                personal information such as your name, contact information,
                address, payment details, and order history. We may also gather
                non-personal information such as browsing data or device
                information to improve your user experience.
              </li>
              <b>2. Use of Information</b>
              <li>
                We use your personal information to process orders, provide
                customer service, send order updates, and improve our services.
                With your consent, we may send promotional offers and updates.
                Non-personal data helps us analyze and enhance our website's
                functionality and user experience.
              </li>
              <b>3. Data Sharing</b>
              <li>
                Masala of India does not sell, trade, or rent your personal
                information to third parties. We may share data with trusted
                partners, such as payment processors and delivery services, to
                complete your orders and provide our services. All partners
                comply with our strict privacy and data security standards.
              </li>
              <b>4. Data Security</b>
              <li>
                We use industry-standard security measures to protect your
                personal information. While no method is 100% secure, we strive
                to protect your data against unauthorized access, disclosure, or
                alteration.
              </li>
              <b>5. Your Rights</b>
              <li>
                You have the right to access, update, or delete your personal
                information at any time by contacting us. If you wish to stop
                receiving promotional emails, you may opt out through your
                account settings or unsubscribe links in our emails.
              </li>
              <b>6. Cookies and Tracking</b>
              <li>
                We may use cookies and similar technologies to improve your
                experience on our website. You can manage your cookie
                preferences in your browser settings, though disabling cookies
                may impact certain features on our site.
              </li>
              <b>7. Changes to Privacy Policy</b>
              <li>
                We may update this Privacy Policy periodically to reflect
                changes in our practices or relevant regulations. Any updates
                will be posted on our website, and your continued use of the
                platform implies acceptance of these changes.
              </li>
            </ul>
            <p>
              For questions or concerns about our Privacy Policy, please contact
              Masala of India's customer support no.{" "}
              <a href="tel:206417-1118">206417-1118</a>. Your privacy is our
              priority, and we are dedicated to protecting it while delivering
              an exceptional food ordering experience.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Privacy;
