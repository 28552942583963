import { Col, Container, Row } from "react-bootstrap";
import "./LunchBuffet.css";
import "animate.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const CommonHomeTestimonial = () => {
  const testimonialbanner = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      showDots: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      showDots: false,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      showDots: false,
    },
  };
  return (
    <>
      <section className="lunchbuffetbg" id="lunchbuffet">
        <div className="container-fluid container-lg h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col lg={12} className="pt-md-5 pt-0">
              <h2 className="text-center pb-2 animate__animated animate__fadeInUp text-light">
                {/* Join us for our daily Lunch Buffet */}
                Indulge Daily <br className="d-block d-md-none" /> Lunch Buffet
                Bliss Awaits You
              </h2>
              <h3 className="text-white text-center">
                All you can eat for <br className="d-block d-md-none" /> $22.99
                only
              </h3>
              {/* <p className="text-white text-center mb-1">
                Vegetarian and Non Veg Dishes
              </p> */}
              {/* <p className="text-white text-center mb-1">7 Days a Week </p> */}
              <p className="text-white text-center pb-0">11 AM to 3 PM</p>
              <div className="text-center">
                <a
                  class="review-btn review-btn-white mb-0 text-white d-inline-block"
                  href="/reservation">
                  Reserve Your Table
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default CommonHomeTestimonial;
